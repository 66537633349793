
// import "./LoginScreen.css";
// import { ImagePath } from "../helper/ImagePath";
// import { Col, Container, Row, Image } from "react-bootstrap";
// import React, { useEffect, useId, useState } from "react";
// import { useNavigat, useLocatione, useLocation, useNavigate } from "react-router-dom";
// import Form from "react-bootstrap/Form";
// import { useDispatch, useSelector } from "react-redux";
// import { emptyTotalEarning, postLogin, postOTP, removeLoginData, storeUserId } from "../store/actions";
// import { setUserCode } from "../helper/helpingMethods";
// import loaderImg from '../../src/assets/gif/pattern.gif';
// import { POST_LOGIN } from "../api/url_helper";
// import axios from "axios";

// function LoginScreen() {
//   const [userId, setUserId] = useState("");
//   const [otp, setOtp] = useState("");
//   const [otpError, setOtpError] = useState("");
//   const [password, setPassword] = useState("");
//   const [isPasswordVisible, setIsPasswordVisible] = useState(false);
//   const [passwordType, setPasswordType] = useState("password");
//   const [rememberMe, setRememberMe] = useState(false);
//   const [loginData, setLoginData] = useState([]);
//   const [checkCondition, setCheckCondition] = useState(0)
//   const [errorId, setErrorId] = useState("");
//   const [errorPassword, setErrorPassword] = useState("");
//   const [error, setError] = useState("");
//   const [otpResponse, setOtpResponse] = useState("")
//   const navigate = useNavigate();
//   const location = useLocation();
//   const dispatch = useDispatch();


//   const handleShowHidePass = () => {
//     setIsPasswordVisible(!isPasswordVisible);
//     setPasswordType(isPasswordVisible ? "password" : "text");
//   };

//   const handleRememberMe = () => {
//     setRememberMe(!rememberMe);
//   };

//   const handleContinue = async () => {
//     setErrorId("");
//     setErrorPassword("");
//     dispatch(removeLoginData());
//     if (!userId) {
//       setErrorId("Please enter ID");
//     }

//     if (!password) {
//       setErrorPassword("Please enter password.");
//     }

//     if (userId && password) {
//       dispatch(
//         postLogin({
//           UserName: userId,
//           Password: password,
//         })
//       );
//     }
//   };
//   const handleUserId = (e) => {
//     setUserId(e.target.value);
//     setErrorId("");
//   };

//   const handlePassword = (e) => {
//     setPassword(e.target.value);
//     setErrorPassword("");
//   };


//   const { loginDeatil } = useSelector((state) => ({
//     loginDeatil: state.logingData,
//   }));

//   const loginSuccess = loginDeatil?.logingData?.success;
//   const userDetails = loginDeatil?.logingData?.data;
//   const storedValue = localStorage.getItem('myValue');

//   useEffect(() => {
//     const searchParamsString = location.search.substring(1);
//     const paramsArray = searchParamsString.split('&');
//     let userName, password;
//     paramsArray.forEach(param => {
//       const [key, value] = param.split(':');
//       if (key === 'UserName') {
//         userName = value;
//       } else if (key === 'Password') {
//         password = value;
//       }
//     });
//     if (userName && password) {
//       const valueToSet = (userName && password) ? 1 : 0;
//       localStorage.setItem('myValue', valueToSet.toString());
//       localStorage.setItem("Slider", JSON.stringify("1"));
//       dispatch(
//         postLogin({
//           UserName: userName,
//           Password: password,
//         })
//       );
//     }

//   }, [location.search])

//   useEffect(() => {
//     if (loginSuccess === true) {
//       setLoginData(userDetails);
//       localStorage.setItem("Slider", JSON.stringify("1"));
//       setUserCode(userDetails[0].username);
//       navigate("/dashboard")
//     }
//   }, [loginSuccess]);




//   return (
//     <React.Fragment>
//       {location.search == "" || location.search == "?_gl=1*" ?

//         <Container fluid>
//           <Row>
//             <div className="col-md-6 Left-container11 ps-0 text-center" style={{ backgroundColor: '#fff' }}>
//               <Image className="mw-100" src={ImagePath.loginImage} style={{ maxHeight: '100vh' }} />
//             </div>
//             <div className="col-md-6 Right-side11 px-4">
//               <Image className="mw-100 mt-5" src={ImagePath.loginScreenLogo} />
//               <div className="mt-5" style={{ justifyContent: "center" }}>
//                 <div className="Login-text-1">Login to Partner Dashboard</div>
//                 <Form>
//                   <div className="mobile-number-container">
//                     <div
//                       style={{
//                         marginTop: 30,
//                       }}
//                     >
//                       <div className="email_wr">
//                         <img src={ImagePath.mail} />
//                         <span>User Id <span className="text-danger">*</span></span>
//                       </div>
//                       <div className="passwrod_input_wr">
//                         <input
//                           type="text"
//                           className="Ipute-user"
//                           placeholder="Please enter user id"
//                           // onChange={(e) => handleUserId(e)}
//                           onChange={handleUserId}
//                         />
//                       </div>

//                       <div className="Login-text-4" style={{ color: 'red', marginTop: "2px" }}>{errorId}</div>

//                     </div>
//                     <div
//                       style={{
//                         marginTop: 30,
//                       }}
//                     >
//                       <div className="email_wr">
//                         <img src={ImagePath.password} />
//                         <span>Password  <span className="text-danger">*</span></span>
//                       </div>
//                       <div className="passwrod_input_wr">
//                         <input
//                           type={passwordType}
//                           className="Ipute-user"
//                           placeholder="Please enter password"
//                           // onChange={handlePassword}
//                           onChange={(e) => handlePassword(e)}
//                         ></input>
//                         <img
//                           src={
//                             isPasswordVisible
//                               ? ImagePath.eyeOpen
//                               : ImagePath.eyeClose
//                           }
//                           style={{ width: "20px" }}
//                           onClick={() => handleShowHidePass()}
//                         />
//                       </div>
//                       <div className="Login-text-4" style={{ color: 'red', marginTop: "2px" }}>{errorPassword}</div>

//                     </div>
//                     <div className="remember_wr">

//                     </div>
//                     <div className="Login-text-4" style={{ color: 'red', marginTop: "2px" }}>{loginDeatil?.logingData?.success == false ? loginDeatil?.logingData?.message : ""}</div>
//                     <div
//                       style={{
//                         marginTop: 5,
//                         justifyContent: "center",
//                         alignItems: "center",
//                       }}
//                     >
//                       <Col xl={12} className="botton-conatiner">
//                         <button
//                           type="button"
//                           className="btn btn-primary"
//                           onClick={() => handleContinue()}
//                         >
//                           <img
//                             src={ImagePath.loginIcon}
//                             style={{ marginRight: "10px" }}
//                           />
//                           Login
//                         </button>

//                       </Col>
//                     </div>
//                   </div>
//                 </Form>
//               </div>
//             </div>
//           </Row>
//         </Container>
//         :
//         <img src={loaderImg} alt="Loading..." className='loader-img ' style={{
//           position: 'absolute',
//           top: '50%',
//           left: '50%',
//           transform: 'translate(-50%, -50%)',
//           width: '150px',
//           height: 'auto',
//         }} />
//       }
//     </React.Fragment>

//   );
// }

// export default LoginScreen;







import "./LoginScreen.css";
import { ImagePath } from "../helper/ImagePath";
import { Col, Container, Row, Image } from "react-bootstrap";
import React, { useEffect, useId, useState } from "react";
import { useNavigat, useLocatione, useLocation, useNavigate } from "react-router-dom";
import { useHistory } from 'react-router-dom'
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import { emptyTotalEarning, postLogin, postOTP, removeLoginData, storeUserId } from "../store/actions";
import { setUserCode } from "../helper/helpingMethods";
import loaderImg from '../../src/assets/gif/pattern.gif';
import { POST_LOGIN } from "../api/url_helper";
import axios from "axios";
import OTPInput from "react-otp-input";
import { colors } from "@mui/material";
import OtpInput from 'react-otp-input';

function LoginScreen() {
  const [userId, setUserId] = useState("");
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [rememberMe, setRememberMe] = useState(false);
  const [loginData, setLoginData] = useState([]);
  const [checkCondition, setCheckCondition] = useState(0)
  const [errorId, setErrorId] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [error, setError] = useState("");
  const [otpResponse, setOtpResponse] = useState("")
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [secondsRemaining, setSecondsRemaining] = useState(30);
  const [timerStart, setTimerStart] = useState(false);

  const { loginDeatil } = useSelector((state) => ({
    loginDeatil: state.logingData,
  }));

  const userDetails = loginDeatil?.logingData?.mobile;
  const loginStatos = loginDeatil?.loginData
  const handleUserId = (e) => {
    setUserId(e.target.value);
    setErrorId("");
  };

  const handleOtp = (e) => {
    setOtp(e.target.value);
    setOtpError("");
  };

  const handleContinue = async () => {
    setErrorId("");
    dispatch(removeLoginData());
    if (!userId) {
      setErrorId("Please enter partner code");
    }
    if (userId) {
      dispatch(
        postLogin({
          username: userId,
        })
      );
      setSecondsRemaining(30);
      setTimerStart(true)
    }
  };


  useEffect(() => {
    if (timerStart == true) {
      const timer = setInterval(() => {
        if (secondsRemaining > 0) {
          setSecondsRemaining((prevSeconds) => prevSeconds - 1);
        } else {
          clearInterval(timer);
        }
      }, 1000);

      // Cleanup on component unmount
      return () => clearInterval(timer);
    }
  }, [secondsRemaining, timerStart]);

  const data = JSON.parse(localStorage.getItem("userData"));
  useEffect(() => {
    if (data?.status === true) {
      // setLoginData(data?.data?.mobile);
      localStorage.setItem("Slider", JSON.stringify("1"));
      // setUserCode(data?.data?.[0].username);
      navigate("/console")
      // navigate("/IncomeScreen")
    }
  }, [data])

  useEffect(() => {
    const searchParamsString = location.search.substring(1);
    const paramsArray = searchParamsString.split('&');
    let userName, password;
    paramsArray.forEach(param => {
      const [key, value] = param.split(':');
      if (key === 'UserName') {
        userName = value;
      }
    });
    if (userName) {
      const valueToSet = (userName) ? 1 : 0;
      localStorage.setItem('myValue', valueToSet.toString());
      localStorage.setItem("Slider", JSON.stringify("1"));
      checkTokenAppUser({ userName })
    }
  }, [location.search])


  const checkTokenAppUser = async ({ userName }) => {
    const url = `https://apinew.bigul.app:4040/api/v1/verify-token`;
    // const payload = { token: "eyJpdiI6InZrOVY5RXNqNU9pWXY3YndKdjR6K3c9PSIsInZhbHVlIjoiNm10RzR4emQ2STUrRnVma05vVHNFQT09IiwibWFjIjoiN2Y0Y2RlNjY4OGExNGY1ZmFiNzM3MTJjZWQ2NTU1MTk0MTdkZjI3NmE0NmUwMTU1NmJhMjJmMWJkYjA3MzNmNCIsInRhZyI6IiJ9" };
    const payload = { token: userName };
    const token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImNkMDUxZGExMDc2MDg1NzVkOGE2NDZhZWM5ZGM0NTI1NGRiYjE4ZjM0OThiOThhZDYwYWI2MDI1ZGU2MjRlZDJhOGIxZTk5YTQ2OTk4NDJiIn0.eyJhdWQiOiI1IiwianRpIjoiY2QwNTFkYTEwNzYwODU3NWQ4YTY0NmFlYzlkYzQ1MjU0ZGJiMThmMzQ5OGI5OGFkNjBhYjYwMjVkZTYyNGVkMmE4YjFlOTlhNDY5OTg0MmIiLCJpYXQiOjE2ODk4NTM5NDYsIm5iZiI6MTY4OTg1Mzk0NiwiZXhwIjoxNzIxNDc2MzQ2LCJzdWIiOiIxMiIsInNjb3BlcyI6W119.FBVXv3ilHKnz0t_I5B1tg54kjwVzM59PMIVCH5F7NZtwqxumiq2I8HsNajHw1w-2oVxlkgHZHvHAx9TmlAJFC55ffdNo3URC3At4_nqMPMsJ2IhO-1FXEqX5iDGN7F3Fr42chvNw3caB7dNBG207zgpJm7XpK-18iqVm6oW034hwKHg8uHz8BhPd_0DW8i9ouQf0RI7BW-W6Q6tQtNyIcLwLrLunQtzAci4rqDaOViESqiCL8bb0ZLtNAtY_DsbLUzOQ1NJF5R9xLhH_yughbeNEdmQgKjuueDBKkiPWvqxZX4GdqtvZKxt4ZgpAHR2m8CvhYWL2HbwmSl9TiYDAvKcAi8gGgI06HodxToP1j2KftfG2c4tTrJ9i2hGbD9o7eV12EQ0ZnMbBdyCc-5GW-hxXoz3j1PDyBRzVGYquihseVoIuOuk_6NieVwc0a7DOJOlpt4nJjttdRQGt5D9f_0_LB89pUwUXuJIKQ5DKWVPpT6PrX5YHxwa4rIDC3_8mXcT6w9qF2TbQwjU7K-baRanWqpHZT9v-d0Mc2GA2aGIIAl_urhGcMLDiMwkEv15OcZ5pBn190s4M0HcoelVKis7cf4PeOcBkgAPUfml-yJlNUWOnFNYVABI0wjdj0NrYki5vLdNt8ZoVsQ9PyNBRWD0815tSmGbVkILJEdvJ-vc"; // Apna token yahan par daalein
    const headers = {
      'Authorization': `Bearer ${token}`
    };

    try {
      const response = await axios.post(url, payload, { headers });
      if (response.status == true) {
        navigate("/console")
        // navigate("/IncomeScreen")
        let ttt = response?.data
        response?.status && localStorage.setItem("userData", JSON.stringify(ttt))
      } else {

      }

    } catch (error) {
      // Handle error here
      console.error('Error:', error.message);
      return null;
    }
  };


  const handleVerifyOtp = () => {
    setOtpError("");
    if (!otp) {
      setOtpError("Please Enter otp");
      return
    }
    handleSubmit()
  };

  const handleSubmit = async () => {
    const url = `https://apinew.bigul.app:4040/api/v1/verify-otp?username=${userId}&otp=${otp}`;
    const token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImNkMDUxZGExMDc2MDg1NzVkOGE2NDZhZWM5ZGM0NTI1NGRiYjE4ZjM0OThiOThhZDYwYWI2MDI1ZGU2MjRlZDJhOGIxZTk5YTQ2OTk4NDJiIn0.eyJhdWQiOiI1IiwianRpIjoiY2QwNTFkYTEwNzYwODU3NWQ4YTY0NmFlYzlkYzQ1MjU0ZGJiMThmMzQ5OGI5OGFkNjBhYjYwMjVkZTYyNGVkMmE4YjFlOTlhNDY5OTg0MmIiLCJpYXQiOjE2ODk4NTM5NDYsIm5iZiI6MTY4OTg1Mzk0NiwiZXhwIjoxNzIxNDc2MzQ2LCJzdWIiOiIxMiIsInNjb3BlcyI6W119.FBVXv3ilHKnz0t_I5B1tg54kjwVzM59PMIVCH5F7NZtwqxumiq2I8HsNajHw1w-2oVxlkgHZHvHAx9TmlAJFC55ffdNo3URC3At4_nqMPMsJ2IhO-1FXEqX5iDGN7F3Fr42chvNw3caB7dNBG207zgpJm7XpK-18iqVm6oW034hwKHg8uHz8BhPd_0DW8i9ouQf0RI7BW-W6Q6tQtNyIcLwLrLunQtzAci4rqDaOViESqiCL8bb0ZLtNAtY_DsbLUzOQ1NJF5R9xLhH_yughbeNEdmQgKjuueDBKkiPWvqxZX4GdqtvZKxt4ZgpAHR2m8CvhYWL2HbwmSl9TiYDAvKcAi8gGgI06HodxToP1j2KftfG2c4tTrJ9i2hGbD9o7eV12EQ0ZnMbBdyCc-5GW-hxXoz3j1PDyBRzVGYquihseVoIuOuk_6NieVwc0a7DOJOlpt4nJjttdRQGt5D9f_0_LB89pUwUXuJIKQ5DKWVPpT6PrX5YHxwa4rIDC3_8mXcT6w9qF2TbQwjU7K-baRanWqpHZT9v-d0Mc2GA2aGIIAl_urhGcMLDiMwkEv15OcZ5pBn190s4M0HcoelVKis7cf4PeOcBkgAPUfml-yJlNUWOnFNYVABI0wjdj0NrYki5vLdNt8ZoVsQ9PyNBRWD0815tSmGbVkILJEdvJ-vc"; // Apna token yahan par daalein
    const headers = {
      'Authorization': `Bearer ${token}`
    };

    try {
      const response = await axios.post(url, null, { headers });
      if (response?.status == true) {
        setOtpResponse(response)
        let ttt = response?.data
        response?.status && localStorage?.setItem("userData", JSON.stringify(ttt))
        navigate("/console")
        // return response.data;
      } else {
        setOtpError(response);

      }

    } catch (error) {
      // Handle error here
      console.error('Error:', error.message);
      return null;
    }
  };

  const remaingSecondConditions = () => {
    setOtp('')
    setSecondsRemaining(30);
    dispatch(
      postLogin({
        username: userId,
      })
    );

  }
  const emptyLoginDetails = () => {
    setUserId("")
    setOtp("")
    dispatch(removeLoginData())
  }
  const handleClick = (event) => {
    event.preventDefault();
    callWebView();
  };

  const callWebView = () => {
    const url = 'https://dkyc.bigul.co/'
    window.open(url, '_blank');
  };

  const becomePatner = () => {
    const url = 'https://bigul.co/partners'
    window.open(url, '_blank');
  }

  return (
    <React.Fragment>
      {location.search.includes("UserName") ?

        <img src={loaderImg} alt="Loading..." className='loader-img ' style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '150px',
          height: 'auto',
        }} />
        :
        <div className="login-wraper">
          <div className="banner-part">
            <img src={ImagePath.image} />
          </div>
          <div className="text-part">
            <div className="logo-header">
              <a href="#">
                {/* <img src={ImagePath.logo_patner} /> */}
                <img src={ImagePath.bonanza} />
              </a>
            </div>
            <div className="login-box">
              <h3 className="title-1">Login to Partner Console</h3>
              {/* Code Partner screen conditions manage*/}
              {loginDeatil?.logingData?.mobile ?
                <></>
                :
                <div className="form-box">
                  <div className="form-group">
                    <label><img src={ImagePath.partnerCodeIcon} /> Partner Code</label>
                    <input type="text" name="" className="form-control" placeholder="Enter partner code" onChange={handleUserId} />
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginRight: '10px' }}>
                    <p className="para">We will send you the OTP on your mobile number.</p>
                    <p style={{ color: 'blue', cursor: 'pointer', textDecoration: 'underline' }} onClick={becomePatner}>Become a partner</p>
                  </div>
                  <div className="Login-text-4" style={{ color: 'red', marginTop: "2px" }}>{loginDeatil?.logingData?.status == false ? loginDeatil?.logingData?.message : errorId} {loginDeatil?.logingData?.is_mobile === false
                    && (
                      <span
                        style={{ color: 'blue', cursor: 'pointer', textDecoration: 'underline' }}
                        onClick={handleClick}
                      >
                        Update now
                      </span>
                    )}
                  </div>
                  <button className="send-btn" style={{ marginTop: "25px" }} onClick={() => handleContinue()}>Send OTP</button>
                </div>
              }
              {/* otp screen conditions manage*/}
              {loginDeatil?.logingData?.mobile ?
                <div className="form-box">
                  <div className="otp-field">
                    <OTPInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={6}
                      inputType="number"
                      renderInput={(props) => <input {...props} onWheel={(e) => e.target.blur()} />}
                    />
                  </div>
                  <p className="para">Enter OTP sent to your register mobile number <span>{(loginDeatil?.logingData?.mobile).replace(/(\d{2})\d+(\d{2})/, "$1******$2")}</span></p>
                  <div className="Login-text-4" style={{ color: 'red', marginTop: "2px" }}>{otpError.status == false ? otpError.message : otp !== "" ? "" : otpError}</div>
                  <div onClick={() => {
                    if (secondsRemaining == 0) {
                      remaingSecondConditions()
                    }
                  }}>
                    <a href="#" class="resend">Resend in (00:{secondsRemaining})</a></div>
                  <button className="send-btn" onClick={() => handleVerifyOtp()}>Verify</button>
                  <button className="back-btn" onClick={() => emptyLoginDetails()}>back</button>
                  {/* <a href="#" className="update_mobile" onClick={handleClick}>Update Mobile Number</a> */}

                </div>
                :
                <></>
              }
            </div>
            {/* status bar manage conditions apply */}

            <div className="status-bar">
              <div className={loginDeatil?.logingData?.mobile ? "bar " : "bar active"}></div>
              <div className={loginDeatil?.logingData?.mobile ? "bar active" : "bar "}></div>
            </div>

          </div>
        </div>

      }
    </React.Fragment>

  );
}

export default LoginScreen;
